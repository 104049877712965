<template>
    <div class="card-orden-compra bg-f5 border br-5 p-2 cr-pointer" @click="verOrden">
        <div class="row mx-0 first-line border-bottom pb-1">
            <div class="col-12 col-lg-6 px-1 f-500 f-14 my-auto">
                Orden: {{ orden }}
            </div>
            <div class="col-12 col-lg-6 px-1">
                    <div class="d-middle justify-content-lg-end">
                    <i :class="`f-18 ${iconoEstado(estado)}`" />
                    <p class="f-12"> {{ nombreEstado(estado) }} </p>
                </div>
            </div>
        </div>
        <div class="row mx-0 pt-1">
            <p class="col-12 d-middle px-1">
                <i class="icon-handshake" />
                {{ proveedor }}
            </p>
            <p class="col-12 d-middle px-1 my-1">
                <i class="icon-format-list-numbered" />
                {{ cant }}
            </p>
            <p class="col-12 col-lg-6 d-middle px-1">
                <i class="icon-cash" />
                {{ formatoMoneda(valor) }}
            </p>
            <p class="col-12 col-lg-6 d-middle px-1">
                <i class="icon-account" />
                {{ cliente }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        orden:{
            type: Number
        },
        estado:{
            type: Number
        },
        proveedor:{
            type: String
        },
        cant:{
            type: Number
        },
        valor:{
            type: Number
        },
        cliente:{
            type: String
        },

    },
    data(){
        return{
           
        }
    },
    methods:{
        nombreEstado(estado){
            switch (estado){
                case 1:
                    return 'Borrador'
                    break;
                case 2:
                    return 'Pendiente'
                    break;
                case 3:
                    return 'Entrega parcial'
                    break;
                case 4:
                    return 'Entrega completa'
                    break;

                default: '-'
                    break;
            }
        },
        iconoEstado(estado){
            switch (estado){
                case 1:
                    return 'icon-progress-pencil text-5d'
                    break;
                case 2:
                    return 'icon-history text-orange'
                    break;
                case 3:
                    return 'icon-circle-check text-info'
                    break;
                case 4:
                    return 'icon-circle-check text-verde'
                    break;
               

                default: '-'
                    break;
            }
        },
        verOrden(){
            this.$emit('verOrden')
        }
    }

}
</script>
<style lang="scss" scoped>
.card-orden-compra{
    box-shadow: 0px 0px 6px #00000040;
    max-width: 320px;
    min-width: 320px;
}
</style>